<template>
  <div class="btn btn-block btn-primary" @click="handleClick">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["to"],
  methods: {
    handleClick() {
      if(this.to) {
        if(this.to.startsWith('http')) {
          window.open(this.to, '_blank')
        } else {
          this.$router.push(this.getLink(this.to))
        }
      }
    },
  },
};
</script>